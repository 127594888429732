

































































































// Core
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Store
import { userNamespace } from '@store/user'
import { LegalPerson, UsersState } from '@store/users/interfaces'

// Types
import { UsersActions } from '@store/users/types'

// Interfaces
import { Meta, Paginate, RequestParams } from '@store/interfaces'
import { IProject } from '@store/user/Interface'

const NSUsers = namespace('usersModule')

@Component({
  name: 'LegalPersons',

  components: {
    'v-dialog-add-legal': () => import('@/components/dialogs/DialogAddLegal.vue'),
    'v-dialog-edit-legal': () => import('@/components/dialogs/DialogEditLegal.vue'),
  },
})
export default class LegalPersonsComponent extends Vue {
  @userNamespace.Getter('project')
  private selectedProject!: IProject

  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @NSUsers.State((state: UsersState) => state.legalPersons.data)
  private legalPersons!: LegalPerson[]

  @NSUsers.State((state: UsersState) => state.legalPersons.meta)
  private meta!: Meta

  @NSUsers.Action(UsersActions.A_FETCH_LEGAL_PERSONS)
  private fetchLegalPersons!: (params?: RequestParams) => Promise<Paginate<LegalPerson>>

  private visibleDialogAddLegal: boolean = false
  private visibleDialogEditLegal: boolean = false

  private params: RequestParams = {
    status: null,
    search: '',
  }

  private loading:boolean = false

  private person: LegalPerson | null = null

  created() {
    this.params.projectId = this.selectedProjectId
    this.changePage()
  }

  @Debounce(1000)
  private handleChangeSearch() {
    const search = this.params.search as string

    if (search.length >= 3 || search.length === 0) {
      this.changePage()
    }
  }


  private async changePage(page = 1) {
    this.loading = true
    await this.fetchLegalPersons({ page, ...this.params })
    this.loading = false
  }

  private handleEditPerson(person: LegalPerson) {
    this.person = person
    this.visibleDialogEditLegal = true
  }
}
